<template>
  <div class="super">
    <div :class="['super__container', { mobileScreen: isMobile }]">
      <div class="df jc-sb ai-c">
        <SupervisorTitle title="Шаблон суточных правил вызова:" />
      </div>
      <div v-loading="isLoading" class="super__content">
        <div class="super__content-title">
          <div v-if="currentTerminal" class="supervisor-title__count-quota">
            Максимальное кол-во авто в буфере&nbsp;:&nbsp;
            {{ currentTerminal['buffer_zone_size'] }}
          </div>

          <div class="title__buttons">
            <AddSupervisorQuotaButton v-if="!isMobile && isShowSamples" />
          </div>
        </div>

        <template v-if="isShowSamples">
          <SampleDay :samples="samplesList" />
        </template>

        <template v-if="!isShowSamples && !isLoading">
          <TableEmpty
            warning="У вас не создано ни одного шаблона правил вызова"
            proposition="Добавить шаблон правил вызова"
            @add="addSample"
          />
        </template>
      </div>
    </div>
    <EditSampleModal />
    <SampleDeleteModal />
  </div>
</template>

<script>
import {
  FETCH_SUPERVISOR_QUOTAS_LINES,
  FETCH_SUPERVISOR_SAMPLES,
  GET_IS_MOBILE,
  GET_LOADINGS_SUPERVISOR_STATISTIC,
  GET_SUPERVISOR_QUOTAS_LINES,
  GET_SUPERVISOR_SAMPLES,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_LIST,
} from '@/store/actions'
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { SUPERVISOR_EDIT_SAMPLE } from '@/constants/dialogs'
import { mapActions, mapGetters } from 'vuex'
import AddSupervisorQuotaButton from '@/views/supervisor/components/AddSupervisorQuotaButton.vue'
import EditSampleModal from '@/views/supervisor/components/dialogs/dialogEditSample/EditSampleModal.vue'
import SampleDay from './components/sample-day/SampleDay.vue'
import SampleDeleteModal from '@/views/supervisor/components/dialogs/dialogDeleteSample/SampleDeleteModal.vue'
import SupervisorTitle from '@/views/supervisor/components/SupervisorTitle.vue'
import TableEmpty from '@/UI/table/table-empty/TableEmpty.vue'
export default {
  name: 'SupervisorSamples',
  components: {
    AddSupervisorQuotaButton,
    EditSampleModal,
    SampleDay,
    SampleDeleteModal,
    TableEmpty,
    SupervisorTitle,
  },
  computed: {
    ...mapGetters({
      cultureList: GET_CULTURE_FROM_STATE,
      terminalId: GET_TERMINAL_CURRENT_ID,
      terminalList: GET_TERMINAL_LIST,
      samplesList: GET_SUPERVISOR_SAMPLES,
      linesList: GET_SUPERVISOR_QUOTAS_LINES,
      isLoading: GET_LOADINGS_SUPERVISOR_STATISTIC,
      isMobile: GET_IS_MOBILE,
    }),
    currentTerminal() {
      if (this.terminalList.length > 0 && this.terminalId) {
        return this.terminalList.find(i => i.id === this.terminalId)
      }

      return null
    },
    isShowSamples() {
      return this.samplesList.length
    },
  },
  watch: {
    terminalId() {
      this.initTerminalData()
    },
  },
  mounted() {
    this.initTerminalData()
  },
  methods: {
    ...mapActions({
      fetchSamples: FETCH_SUPERVISOR_SAMPLES,
      fetchLines: FETCH_SUPERVISOR_QUOTAS_LINES,
    }),
    initTerminalData() {
      this.fetchLines()
      this.fetchSamples()
    },
    addSample() {
      this.setDialog({ name: SUPERVISOR_EDIT_SAMPLE, visible: true })
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./styles/table-and-samples"
</style>
