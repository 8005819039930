<template>
  <div class="quota-day">
    <div class="quota-day__content mod-sample">
      <component
        :is="getCurrentComponent(item)"
        v-for="item in samples"
        :key="item.id"
        :quota="item"
      />
    </div>
  </div>
</template>

<script>
import SampleMultiple from './components/SampleMultiple'
import SampleSingle from './components/SampleSingle'

export default {
  name: 'SampleDay',
  comments: { SampleSingle, SampleMultiple },
  props: {
    samples: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getCurrentComponent(item) {
      return item.length > 1 ? SampleMultiple : SampleSingle
    },
  },
}
</script>

<style lang="sass">

.quota-day
  padding: 22px 22px 32px
  display: flex
  justify-content: space-between
  align-items: center
  background: #FFFFFF
  border: $border-default
  box-shadow: $box-shadow-default
  border-radius: 8px
  margin-bottom: 11px

  .quota-single + .quota-multiple,
  .quota-multiple + .quota-single
    margin-top: 30px

  &__content.mod-sample
    flex: 1

  // общие стили для компонентов QuotaSingle и QuotaMultiple
  &__time
    flex: 0 0 238px
    margin-right: 15px
    font-weight: bold
    font-size: 15px
    line-height: 16px
    letter-spacing: 0.5px
    text-transform: uppercase
    color: #7E7E7E
    display: flex
    justify-content: flex-start
    align-items: stretch

  &__time-text
    margin-right: 6px
    display: flex
    justify-content: center
    align-items: center

  &__time-val
    margin-right: 6px
    padding: 7px 8px
    background: #F5F7FA
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center
    font-size: 14px
    color: #606266
    border: 1px solid #EBEEF5

    &--long
      font-size: 14px
      text-transform: lowercase
      padding: 0
      width: 70px
      margin-left: 5px
      border: none
    &:last-of-type
      margin-right: 0

@media (max-width: 800px)
  .quota-day
    padding: 16px 18px 22px 16px
    flex-direction: column
    align-items: flex-start
    &__title
      flex: auto
      flex-wrap: nowrap
      align-self: auto
      margin: 0 0 6px
      &-slash
        margin: 0 6px
      &-day
        flex: auto
      &-weekday
        flex: auto
    &__content
      flex: auto !important
      width: 100%
</style>
