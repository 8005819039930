var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quota-day" }, [
    _c(
      "div",
      { staticClass: "quota-day__content mod-sample" },
      _vm._l(_vm.samples, function (item) {
        return _c(_vm.getCurrentComponent(item), {
          key: item.id,
          tag: "component",
          attrs: { quota: item },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }