var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "super" },
    [
      _c(
        "div",
        { class: ["super__container", { mobileScreen: _vm.isMobile }] },
        [
          _c(
            "div",
            { staticClass: "df jc-sb ai-c" },
            [
              _c("SupervisorTitle", {
                attrs: { title: "Шаблон суточных правил вызова:" },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "super__content",
            },
            [
              _c("div", { staticClass: "super__content-title" }, [
                _vm.currentTerminal
                  ? _c(
                      "div",
                      { staticClass: "supervisor-title__count-quota" },
                      [
                        _vm._v(
                          " Максимальное кол-во авто в буфере :  " +
                            _vm._s(_vm.currentTerminal["buffer_zone_size"]) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "title__buttons" },
                  [
                    !_vm.isMobile && _vm.isShowSamples
                      ? _c("AddSupervisorQuotaButton")
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm.isShowSamples
                ? [_c("SampleDay", { attrs: { samples: _vm.samplesList } })]
                : _vm._e(),
              !_vm.isShowSamples && !_vm.isLoading
                ? [
                    _c("TableEmpty", {
                      attrs: {
                        warning:
                          "У вас не создано ни одного шаблона правил вызова",
                        proposition: "Добавить шаблон правил вызова",
                      },
                      on: { add: _vm.addSample },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c("EditSampleModal"),
      _c("SampleDeleteModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }